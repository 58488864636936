document.addEventListener('DOMContentLoaded', function () {
  const currentPath = window.location.pathname;
  const links = document.querySelectorAll('.site-nav__title');

  links.forEach(link => {
    const linkPath = link.getAttribute('href');
    if (
      linkPath === currentPath ||
      (currentPath === '/' && linkPath === './')
    ) {
      link.classList.add('site-nav__title--accent');
    }
  });

  const mobileLinks = document.querySelectorAll('.menu-container__title');
  mobileLinks.forEach(link => {
    const linkPath = new URL(link.href).pathname;
    if (
      linkPath === currentPath ||
      (currentPath === '/' && linkPath === './')
    ) {
      link.classList.add('menu-container__title--accent');
    }
  });
});
